@charset "utf-8";

@import "tools/mixins";
@import "tools/var";

.faq-list {
  &-item {
    display: flex;
    justify-content: space-between;
    margin: 0 0 120px;
    @include mq(sp) {
      display: block;
      margin: 0 0 60px;
    }
    &:last-child {
      margin: 0;
    }
    dl {
      width: 73.64066193853428%;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $line;
      @include mq(sp) {
        width: auto;
      }
      dt {
        position: relative;
        padding: 20px 60px 20px 80px;
        border-top: 1px solid $line;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        @include mq(sp) {
          padding: 15px 30px 15px 30px;
          font-size: 12px;
        }
        &:after {
          content: "＋";
          position: absolute;
          top: 50%;
          right: 20px;
          line-height: 1;
          font-size: 40px;
          font-weight: normal;
          transform: translateY(-50%);
          @include mq(sp) {
            right: 0;
            font-size: 24px;
          }
        }
        &.is-open {
          &:after {
            content: "－";
          }
        }
        span {
          position: absolute;
          top: 20px;
          left: 30px;
          font-family: $font-gt;
          @include mq(sp) {
            top: 15px;
            left: 0;
          }
        }
      }
      dd {
        display: none;
        position: relative;
        margin: 0 0 -1px;
        padding: 0 0 20px 80px;
        border-bottom: 1px solid $line;
        @include mq(sp) {
          padding: 0 0 15px 30px;
        }
        span {
          position: absolute;
          top: -4px;
          left: 30px;
          color: $secondary;
          font-size: 16px;
          font-weight: bold;
          font-family: $font-gt;
          @include mq(sp) {
            top: 0;
            left: 0;
            font-size: 12px;
          }
        }
        p {
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }
  &-ttl {
    margin: 0;
    color: $primary;
    font-size: 16px;
    @include mq(sp) {
      margin: 0 0 15px;
      font-size: 13px;
    }
  }
}

.faq-contact-btn {
  margin: 120px 0 0;
  @include mq(sp) {
    margin: 40px 0 0;
  }
  a {
    display: block;
    position: relative;
    padding: 45px 50px 45px 70px;
    border: 2px solid $primary;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    background: $primary;
    @include mq(sp) {
      padding: 20px;
      font-size: 13px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 90px;
      width: 10px;
      height: 10px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg) translateY(-50%);
      transition: .3s;
      @include mq(sp) {
        right: 20px;
        width: 8px;
        height: 8px;
      }
    }
    &:hover {
      @include mq(pc) {
        color: $primary;
        background: #fff;
        &:after {
          border-color: $primary;
        }
      }
    }
  }
}

.form {
  &-lead {
    margin: 0 0 40px;
    font-size: 14px;
    text-align: center;
    @include mq(sp) {
      margin: 0 0 30px;
      font-size: inherit;
      text-align: left;
    }
  }
  &-step {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 60px;
    padding: 50px 0;
    border: 2px solid $line;
    color: #c4c4cd;
    font-size: 14px;
    @include mq(sp) {
      flex-direction: column;
      margin: 0 0 40px;
      padding: 20px;
      font-size: inherit;
    }
    &-item {
      display: flex;
      align-items: center;
      @include mq(sp) {
        flex-direction: column;
      }
      & + & {
        &:before {
          content: "";
          display: block;
          width: 34px;
          height: 9px;
          margin: 0 30px;
          background: url(/assets/img/faq/form/icon-arrow-01.svg) no-repeat 0 0;
          background-size: 34px 9px;
          @include mq(sp) {
            width: 30px;
            height: 8px;
            margin: 20px 0;
            background-size: 30px 8px;
            transform: rotate(90deg);
          }
        }
      }
      &.is-active {
        color: $primary;
      }
    }
  }
  table {
    width: 100%;
    margin: 0 0 50px;
    border-collapse: collapse;
    @include mq(sp) {
      border-top: 1px solid $line;
    }
    th {
      position: relative;
      width: 240px;
      padding: 25px 0;
      border-top: 1px solid $line;
      border-bottom: 1px solid $line;
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      @include mq(sp) {
        display: block;
        width: auto;
        padding: 20px 0 5px;
        border: none;
        font-size: inherit;
      }
      .required {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        line-height: 1;
        padding: 5px;
        border-radius: 3px;
        color: #fff;
        font-size: 12px;
        background: #e92841;
        transform: translateY(-50%);
        @include mq(sp) {
          top: 22px;
          font-size: 10px;
          transform: none;
        }
      }
    }
    td {
      padding: 25px 0 25px 20px;
      border-top: 1px solid $line;
      border-bottom: 1px solid $line;
      @include mq(sp) {
        display: block;
        padding: 0 0 20px;
        border-top: none;
      }
      .input-notes {
        margin-bottom: 10px;
        &:last-child {
          margin: 10px 0 0;
        }
      }
      .input-notes-02 {
        position: relative;
        padding: 0 0 0 1em;
        font-size: 11px;
        @include mq(sp) {
          font-size: 9px;
        }
        &:before {
          content: "※";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .mwform-tel-field {
      display: flex;
      align-items: center;
      input {
        width: 120px;
        margin: 0 10px;
        @include mq(sp) {
          flex: 1;
          width: auto;
          margin: 0 5px;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .input-notes {
      display: block;
      font-size: 11px;
      @include mq(sp) {
        font-size: 9px;
      }
    }
    .input-radio {
      .mwform-radio-field {
        margin-left: 0 !important;
        &.horizontal-item {
          display: inline-block;
          margin-right: 20px;
          &:last-of-type {
            margin-right: 0;
          }
        }
        &.vartical-item {
          display: block;
          & + .mwform-radio-field {
            margin: 5px 0 0;
          }
        }
      }
      label {
        display: block;
        position: relative;
        padding: 0 0 0 30px;
        cursor: pointer;
      }
      input[type="radio"] {
        position: absolute;
        opacity: 0;
        -webkit-appearance: none;
        appearance: none;
        & + span:before {
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          left: 0;
          width: 16px;
          height: 16px;
          border: 1px solid #c4c4cd;
          border-radius: 100%;
          background: #ececef;
        }
        &:checked + span:after {
          content: "";
          position: absolute;
          top: 8px;
          left: 4px;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: $primary;
        }
      }
    }
    .input-checkbox {
      .mwform-checkbox-field {
        margin-left: 0 !important;
        &.horizontal-item {
          display: inline-block;
          margin-right: 20px;
          &:last-of-type {
            margin-right: 0;
          }
        }
        &.vartical-item {
          display: block;
          & + .mwform-checkbox-field {
            margin: 5px 0 0;
          }
        }
      }
      label {
        display: block;
        position: relative;
        padding: 0 0 0 30px;
        cursor: pointer;
      }
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        -webkit-appearance: none;
        appearance: none;
        & + span:before {
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          left: 0;
          width: 16px;
          height: 16px;
          border: 1px solid #c4c4cd;
          background: #ececef;
        }
        &:checked + span:after {
          content: "";
          position: absolute;
          top: 6px;
          left: 5px;
          width: 5px;
          height: 9px;
          border-right: 3px solid $primary;
          border-bottom: 3px solid $primary;
          transform: rotate(45deg);
        }
      }
    }
    .input-counter {
      margin: 10px 0 0;
      font-size: 11px;
      @include mq(sp) {
        font-size: 9px;
      }
    }
    .input-date {
      display: flex;
      align-items: center;
      @include mq(sp) {
        flex-wrap: wrap;
      }
      & + .input-date {
        margin: 20px 0 0;
      }
      &-inner {
        display: flex;
        align-items: center;
        & + .input-date-inner {
          margin: 0 0 0 20px;
          @include mq(sp) {
            margin: 0 0 0 15px;
          }
        }
        &:nth-child(1) {
          @include mq(sp) {
            width: 100%;
            margin: 0 0 10px;
          }
        }
        &:nth-child(2) {
          @include mq(sp) {
            margin: 0 !important;
          }
        }
      }
      input[type="text"] {
        width: 150px;
        margin: 0 0 0 10px;
        @include mq(sp) {
          width: 200px;
        }
      }
      select {
        height: 55px;
        line-height: inherit;
        margin: 0 0 0 10px;
        padding: 0 25px;
        border: none;
        outline: none;
        background: #ececef;
        @include mq(sp) {
          padding: 0 10px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .input-file {
      display: flex;
      align-items: center;
      @include mq(sp) {
        display: block;
      }
      &-label {
        display: block;
        width: 13em;
        @include mq(sp) {
          width: auto;
        }
      }
      & + .input-file {
        margin: 10px 0 0;
      }
    }
  }
  .error {
    display: block;
    margin: 10px 0;
    color: #e92841 !important;
    font-size: inherit !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    line-height: inherit;
    padding: 13px 25px;
    border: none;
    outline: none;
    vertical-align: top;
    background: #ececef;
    box-sizing: border-box;
    @include mq(sp) {
      padding: 10px 15px;
    }
  }
  &-privacy {
    margin: 0 0 100px;
    font-size: 14px;
    text-align: center;
    @include mq(sp) {
      margin: 0 0 30px;
      font-size: inherit;
      text-align: left;
    }
  }
  &-btn {
    display: flex;
    justify-content: center;
    position: relative;
    @include mq(sp) {
      flex-direction: column;
    }
    button[name="submitConfirm"],
    button[name="submit"],
    button[name="submitBack"],
    a {
      display: inline-block;
      position: relative;
      line-height: inherit;
      width: 400px;
      margin: 0;
      padding: 20px 50px;
      border: 2px solid $primary;
      color: $primary;
      font-size: 16px;
      text-align: left;
      text-decoration: none;
      background: #fff;
      transition: .3s;
      box-sizing: border-box;
      cursor: pointer;
      @include mq(sp) {
        order: 1;
        display: block;
        width: 100%;
        padding: 15px 20px;
        border-width: 1px;
        font-size: inherit;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 50px;
        width: 10px;
        height: 10px;
        border-top: 1px solid $primary;
        border-right: 1px solid $primary;
        transform: translateY(-50%) rotate(45deg);
        transition: .3s;
        @include mq(sp) {
          right: 20px;
          width: 7px;
          height: 7px;
        }
      }
      &:hover {
        @include mq(pc) {
          color: #fff;
          background: $primary;
          &:after {
            border-color: #fff;
          }
        }
      }
    }
    button[name="submitConfirm"] {
      width: 480px;
      color: #fff;
      background: $primary;
      @include mq(sp) {
        width: auto;
      }
      &:after {
        border-color: #fff;
      }
      &:hover {
        @include mq(pc) {
          color: $primary;
          background: #fff;
          &:after {
            border-color: $primary;
          }
        }
      }
    }
    button[name="submit"] {
      text-align: center;
    }
    button[name="submitBack"] {
      margin: 0 15px 0 0;
      text-align: center;
      @include mq(sp) {
        order: 2;
        margin: 10px 0 0;
      }
      &:after {
        right: auto;
        left: 50px;
        transform: translateY(-50%) rotate(-135deg);
        @include mq(sp) {
          left: 20px;
        }
      }
    }
  }
  .mw_wp_form_confirm {
    .required {
      display: none;
    }
    .input-notes {
      display: none;
    }
    .input-notes-02 {
      display: none;
    }
    .input-counter {
      display: none;
    }
    .confirm-hide {
      display: none;
    }
  }
}
