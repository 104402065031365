@charset "UTF-8";
.faq-list-item {
  display: flex;
  justify-content: space-between;
  margin: 0 0 120px;
}
@media screen and (max-width: 767px) {
  .faq-list-item {
    display: block;
    margin: 0 0 60px;
  }
}
.faq-list-item:last-child {
  margin: 0;
}
.faq-list-item dl {
  width: 73.6406619385%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (max-width: 767px) {
  .faq-list-item dl {
    width: auto;
  }
}
.faq-list-item dl dt {
  position: relative;
  padding: 20px 60px 20px 80px;
  border-top: 1px solid #e6e6e6;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .faq-list-item dl dt {
    padding: 15px 30px 15px 30px;
    font-size: 12px;
  }
}
.faq-list-item dl dt:after {
  content: "＋";
  position: absolute;
  top: 50%;
  right: 20px;
  line-height: 1;
  font-size: 40px;
  font-weight: normal;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .faq-list-item dl dt:after {
    right: 0;
    font-size: 24px;
  }
}
.faq-list-item dl dt.is-open:after {
  content: "－";
}
.faq-list-item dl dt span {
  position: absolute;
  top: 20px;
  left: 30px;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
}
@media screen and (max-width: 767px) {
  .faq-list-item dl dt span {
    top: 15px;
    left: 0;
  }
}
.faq-list-item dl dd {
  display: none;
  position: relative;
  margin: 0 0 -1px;
  padding: 0 0 20px 80px;
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (max-width: 767px) {
  .faq-list-item dl dd {
    padding: 0 0 15px 30px;
  }
}
.faq-list-item dl dd span {
  position: absolute;
  top: -4px;
  left: 30px;
  color: #00a7b5;
  font-size: 16px;
  font-weight: bold;
  font-family: "GT-Walsheim-Pro", "Yu Gothic", YuGothic, sans-serif;
}
@media screen and (max-width: 767px) {
  .faq-list-item dl dd span {
    top: 0;
    left: 0;
    font-size: 12px;
  }
}
.faq-list-item dl dd p:first-of-type {
  margin-top: 0;
}
.faq-list-ttl {
  margin: 0;
  color: #4f2d7f;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .faq-list-ttl {
    margin: 0 0 15px;
    font-size: 13px;
  }
}

.faq-contact-btn {
  margin: 120px 0 0;
}
@media screen and (max-width: 767px) {
  .faq-contact-btn {
    margin: 40px 0 0;
  }
}
.faq-contact-btn a {
  display: block;
  position: relative;
  padding: 45px 50px 45px 70px;
  border: 2px solid #4f2d7f;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  background: #4f2d7f;
}
@media screen and (max-width: 767px) {
  .faq-contact-btn a {
    padding: 20px;
    font-size: 13px;
  }
}
.faq-contact-btn a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 90px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg) translateY(-50%);
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .faq-contact-btn a:after {
    right: 20px;
    width: 8px;
    height: 8px;
  }
}
@media screen and (min-width: 768px) {
  .faq-contact-btn a:hover {
    color: #4f2d7f;
    background: #fff;
  }
  .faq-contact-btn a:hover:after {
    border-color: #4f2d7f;
  }
}

.form-lead {
  margin: 0 0 40px;
  font-size: 14px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .form-lead {
    margin: 0 0 30px;
    font-size: inherit;
    text-align: left;
  }
}
.form-step {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 60px;
  padding: 50px 0;
  border: 2px solid #e6e6e6;
  color: #c4c4cd;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .form-step {
    flex-direction: column;
    margin: 0 0 40px;
    padding: 20px;
    font-size: inherit;
  }
}
.form-step-item {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .form-step-item {
    flex-direction: column;
  }
}
.form-step-item + .form-step-item:before {
  content: "";
  display: block;
  width: 34px;
  height: 9px;
  margin: 0 30px;
  background: url(/assets/img/faq/form/icon-arrow-01.svg) no-repeat 0 0;
  background-size: 34px 9px;
}
@media screen and (max-width: 767px) {
  .form-step-item + .form-step-item:before {
    width: 30px;
    height: 8px;
    margin: 20px 0;
    background-size: 30px 8px;
    transform: rotate(90deg);
  }
}
.form-step-item.is-active {
  color: #4f2d7f;
}
.form table {
  width: 100%;
  margin: 0 0 50px;
  border-collapse: collapse;
}
@media screen and (max-width: 767px) {
  .form table {
    border-top: 1px solid #e6e6e6;
  }
}
.form table th {
  position: relative;
  width: 240px;
  padding: 25px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .form table th {
    display: block;
    width: auto;
    padding: 20px 0 5px;
    border: none;
    font-size: inherit;
  }
}
.form table th .required {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  line-height: 1;
  padding: 5px;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  background: #e92841;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .form table th .required {
    top: 22px;
    font-size: 10px;
    transform: none;
  }
}
.form table td {
  padding: 25px 0 25px 20px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (max-width: 767px) {
  .form table td {
    display: block;
    padding: 0 0 20px;
    border-top: none;
  }
}
.form table td .input-notes {
  margin-bottom: 10px;
}
.form table td .input-notes:last-child {
  margin: 10px 0 0;
}
.form table td .input-notes-02 {
  position: relative;
  padding: 0 0 0 1em;
  font-size: 11px;
}
@media screen and (max-width: 767px) {
  .form table td .input-notes-02 {
    font-size: 9px;
  }
}
.form table td .input-notes-02:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}
.form table .mwform-tel-field {
  display: flex;
  align-items: center;
}
.form table .mwform-tel-field input {
  width: 120px;
  margin: 0 10px;
}
@media screen and (max-width: 767px) {
  .form table .mwform-tel-field input {
    flex: 1;
    width: auto;
    margin: 0 5px;
  }
}
.form table .mwform-tel-field input:first-child {
  margin-left: 0;
}
.form table .input-notes {
  display: block;
  font-size: 11px;
}
@media screen and (max-width: 767px) {
  .form table .input-notes {
    font-size: 9px;
  }
}
.form table .input-radio .mwform-radio-field {
  margin-left: 0 !important;
}
.form table .input-radio .mwform-radio-field.horizontal-item {
  display: inline-block;
  margin-right: 20px;
}
.form table .input-radio .mwform-radio-field.horizontal-item:last-of-type {
  margin-right: 0;
}
.form table .input-radio .mwform-radio-field.vartical-item {
  display: block;
}
.form table .input-radio .mwform-radio-field.vartical-item + .mwform-radio-field {
  margin: 5px 0 0;
}
.form table .input-radio label {
  display: block;
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
}
.form table .input-radio input[type=radio] {
  position: absolute;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
}
.form table .input-radio input[type=radio] + span:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #c4c4cd;
  border-radius: 100%;
  background: #ececef;
}
.form table .input-radio input[type=radio]:checked + span:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #4f2d7f;
}
.form table .input-checkbox .mwform-checkbox-field {
  margin-left: 0 !important;
}
.form table .input-checkbox .mwform-checkbox-field.horizontal-item {
  display: inline-block;
  margin-right: 20px;
}
.form table .input-checkbox .mwform-checkbox-field.horizontal-item:last-of-type {
  margin-right: 0;
}
.form table .input-checkbox .mwform-checkbox-field.vartical-item {
  display: block;
}
.form table .input-checkbox .mwform-checkbox-field.vartical-item + .mwform-checkbox-field {
  margin: 5px 0 0;
}
.form table .input-checkbox label {
  display: block;
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
}
.form table .input-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
}
.form table .input-checkbox input[type=checkbox] + span:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #c4c4cd;
  background: #ececef;
}
.form table .input-checkbox input[type=checkbox]:checked + span:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 5px;
  width: 5px;
  height: 9px;
  border-right: 3px solid #4f2d7f;
  border-bottom: 3px solid #4f2d7f;
  transform: rotate(45deg);
}
.form table .input-counter {
  margin: 10px 0 0;
  font-size: 11px;
}
@media screen and (max-width: 767px) {
  .form table .input-counter {
    font-size: 9px;
  }
}
.form table .input-date {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .form table .input-date {
    flex-wrap: wrap;
  }
}
.form table .input-date + .input-date {
  margin: 20px 0 0;
}
.form table .input-date-inner {
  display: flex;
  align-items: center;
}
.form table .input-date-inner + .input-date-inner {
  margin: 0 0 0 20px;
}
@media screen and (max-width: 767px) {
  .form table .input-date-inner + .input-date-inner {
    margin: 0 0 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .form table .input-date-inner:nth-child(1) {
    width: 100%;
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .form table .input-date-inner:nth-child(2) {
    margin: 0 !important;
  }
}
.form table .input-date input[type=text] {
  width: 150px;
  margin: 0 0 0 10px;
}
@media screen and (max-width: 767px) {
  .form table .input-date input[type=text] {
    width: 200px;
  }
}
.form table .input-date select {
  height: 55px;
  line-height: inherit;
  margin: 0 0 0 10px;
  padding: 0 25px;
  border: none;
  outline: none;
  background: #ececef;
}
@media screen and (max-width: 767px) {
  .form table .input-date select {
    padding: 0 10px;
  }
}
.form table .input-date select:last-child {
  margin-right: 0;
}
.form table .input-file {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .form table .input-file {
    display: block;
  }
}
.form table .input-file-label {
  display: block;
  width: 13em;
}
@media screen and (max-width: 767px) {
  .form table .input-file-label {
    width: auto;
  }
}
.form table .input-file + .input-file {
  margin: 10px 0 0;
}
.form .error {
  display: block;
  margin: 10px 0;
  color: #e92841 !important;
  font-size: inherit !important;
}
.form .error:last-child {
  margin-bottom: 0;
}
.form input[type=text],
.form input[type=email],
.form textarea {
  width: 100%;
  line-height: inherit;
  padding: 13px 25px;
  border: none;
  outline: none;
  vertical-align: top;
  background: #ececef;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .form input[type=text],
  .form input[type=email],
  .form textarea {
    padding: 10px 15px;
  }
}
.form-privacy {
  margin: 0 0 100px;
  font-size: 14px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .form-privacy {
    margin: 0 0 30px;
    font-size: inherit;
    text-align: left;
  }
}
.form-btn {
  display: flex;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 767px) {
  .form-btn {
    flex-direction: column;
  }
}
.form-btn button[name=submitConfirm],
.form-btn button[name=submit],
.form-btn button[name=submitBack],
.form-btn a {
  display: inline-block;
  position: relative;
  line-height: inherit;
  width: 400px;
  margin: 0;
  padding: 20px 50px;
  border: 2px solid #4f2d7f;
  color: #4f2d7f;
  font-size: 16px;
  text-align: left;
  text-decoration: none;
  background: #fff;
  transition: 0.3s;
  box-sizing: border-box;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .form-btn button[name=submitConfirm],
  .form-btn button[name=submit],
  .form-btn button[name=submitBack],
  .form-btn a {
    order: 1;
    display: block;
    width: 100%;
    padding: 15px 20px;
    border-width: 1px;
    font-size: inherit;
  }
}
.form-btn button[name=submitConfirm]:after,
.form-btn button[name=submit]:after,
.form-btn button[name=submitBack]:after,
.form-btn a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 50px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #4f2d7f;
  border-right: 1px solid #4f2d7f;
  transform: translateY(-50%) rotate(45deg);
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .form-btn button[name=submitConfirm]:after,
  .form-btn button[name=submit]:after,
  .form-btn button[name=submitBack]:after,
  .form-btn a:after {
    right: 20px;
    width: 7px;
    height: 7px;
  }
}
@media screen and (min-width: 768px) {
  .form-btn button[name=submitConfirm]:hover,
  .form-btn button[name=submit]:hover,
  .form-btn button[name=submitBack]:hover,
  .form-btn a:hover {
    color: #fff;
    background: #4f2d7f;
  }
  .form-btn button[name=submitConfirm]:hover:after,
  .form-btn button[name=submit]:hover:after,
  .form-btn button[name=submitBack]:hover:after,
  .form-btn a:hover:after {
    border-color: #fff;
  }
}
.form-btn button[name=submitConfirm] {
  width: 480px;
  color: #fff;
  background: #4f2d7f;
}
@media screen and (max-width: 767px) {
  .form-btn button[name=submitConfirm] {
    width: auto;
  }
}
.form-btn button[name=submitConfirm]:after {
  border-color: #fff;
}
@media screen and (min-width: 768px) {
  .form-btn button[name=submitConfirm]:hover {
    color: #4f2d7f;
    background: #fff;
  }
  .form-btn button[name=submitConfirm]:hover:after {
    border-color: #4f2d7f;
  }
}
.form-btn button[name=submit] {
  text-align: center;
}
.form-btn button[name=submitBack] {
  margin: 0 15px 0 0;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .form-btn button[name=submitBack] {
    order: 2;
    margin: 10px 0 0;
  }
}
.form-btn button[name=submitBack]:after {
  right: auto;
  left: 50px;
  transform: translateY(-50%) rotate(-135deg);
}
@media screen and (max-width: 767px) {
  .form-btn button[name=submitBack]:after {
    left: 20px;
  }
}
.form .mw_wp_form_confirm .required {
  display: none;
}
.form .mw_wp_form_confirm .input-notes {
  display: none;
}
.form .mw_wp_form_confirm .input-notes-02 {
  display: none;
}
.form .mw_wp_form_confirm .input-counter {
  display: none;
}
.form .mw_wp_form_confirm .confirm-hide {
  display: none;
}